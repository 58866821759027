<template>
  <div class="upLoadImg">
    <el-upload class="avatar-uploader" :show-file-list="false" :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload" :http-request="handleUpload" v-cloak action="">
      <img v-if="imageUrl" :src="imageUrl || propImg || newsCoverImg" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
import {
  client,
  headers,
  getFileNameUUID
} from '../../api/oss/alioss'
export default {
  props: {
    propImg: [String],
    newsCoverImg: [String],
    imageUrl: [String]
  },
  data() {
    return {
      Addfrom: {
        url: ""
      }
    };
  },
  methods: {
    handleUpload(file) {
      console.log(file, '图片组件被调用');
      //注意哦，这里指定文件夹'images/'，尝试过写在配置文件，但是各种不行，写在这里就可以
      var fileName = 'images/' + `${Date.parse(new Date())}` + '.jpg';
      if (!file.file) {
        client().multipartUpload(fileName, file, {
          headers
        }, {
          progress: function(percentage, cpt) {
            console.log('打印进度', percentage)
          }
        }).then((res) => {
          console.log("resresfile",res)
          let data={
            url:"https://sisheng-resource.oss-cn-shenzhen.aliyuncs.com/"+fileName
          }
           this.$emit("UpLoadImg", data);
        })
      } else {
        client().multipartUpload(fileName, file.file, {
          headers
        }, {
          progress: function(percentage, cpt) {
            console.log('打印进度', percentage)
          }
        }).then((res) => {
          console.log("resresfile",res)
          let data={
            url:"https://sisheng-teacher.oss-cn-shenzhen.aliyuncs.com/"+fileName
          }
           this.$emit("UpLoadImg", data);
        })
      }


    },
    handleAvatarSuccess(res, file) {
      console.log(res, file, "55");
      // this.imageUrl = URL.createObjectURL(file.raw);
      this.$emit("UpLoadImg", res);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
  created() {

  },
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px dashed #ddd;
  // &:hover {
  //   border-color: var(--mcol);
  // }
  // i {
  //   font-size: 28px;
  //   color: #8c939d;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  // }
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;

  .avatar-uploader-icon {
    color: #409eff;
  }
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  line-height: 178px;
  text-align: center;
  transition: all 0.2s linear;
  width: 178px;
}

.avatar {
  display: block;
  width: 100px;
  height: 100px;
}
</style>
