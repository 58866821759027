<template>
  <div class="revise">
    <div class="title">
      <span @click="$router.push({ path: '/PersonalCenter' })">个人中心 /</span>
      个人账户
    </div>
    <div class="main">
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-form-item label="用户名" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="显示角色">
          <span>教师</span>
        </el-form-item>
        <el-form-item label="账号" prop="user_name">
          <el-input v-model="form.user_name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="上传头像">
          <fileimg @UpLoadImg="UpLoadImg" :imageUrl="imgurl" ref="FileImg" />
          <!-- <el-upload
            class="avatar-uploader"
            :headers="headers"
            action="http://teacher.sishengsports.cn/api/upload" 
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="form.avatar" :src="form.avatar" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              支持JPG,PNG图片格式，文件小于2M，图片未侵犯他人权益
            </div>
          </el-upload> -->
        </el-form-item>
        <el-form-item label="修改密码">
          <el-switch v-model="delivery"></el-switch>
        </el-form-item>
        <el-form-item label="旧密码" prop="old_pass" v-show="delivery">
          <el-input
            show-password
            type="password"
            v-model="form.old_pass"
            placeholder="密码至少为8位,包含数字、大小写字母以及特殊字符"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password" v-show="delivery">
          <el-input
            show-password
            type="password"
            v-model="form.password"
            placeholder="密码至少为8位,包含数字、大小写字母以及特殊字符"></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          prop="password_confirm"
          v-show="delivery">
          <el-input
            type="password"
            show-password
            v-model="form.password_confirm"
            placeholder="密码至少为8位,包含数字、大小写字母以及特殊字符"></el-input>
        </el-form-item>
        <!--        <el-form-item label="验证码" prop="code" v-show="delivery">
          <el-input v-model="form.code"></el-input>
          <div class="btn_code" @click="getcode">获取验证码</div>
        </el-form-item> -->
        <!-- <el-form-item label="个人荣誉">
          <el-input type="textarea" v-model="form.honor" :rows="5"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import fileimg from "../../components/fileImg/index.vue";
export default {
  components: {
    fileimg,
  },
  data() {
    const checkFinanceCode = (rule, value, callback) => {
      if (value) {
        // var reg =
        //   /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F])[\da-zA-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]{8,}$/;

        var reg =
          /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F])[\da-zA-Z\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]{8,}$/;
        if (reg.test(value) === false) {
          callback(new Error("密码至少为8位,包含数字、大小写字母以及特殊字符"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      form: {
        name: "",
        user_name: "",
        mobile: "",
        avatar: "",
        password: "",
        old_pass: "",
        password_confirm: "",
        code: "",
        honor: "",
      },
      imageUrl: "",
      imgurl: "",
      delivery: false,
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        user_name: [{ required: true, message: "账号", trigger: "blur" }],
        mobile: [{ required: true, message: "手机号", trigger: "blur" }],
        old_pass: [
          // { required: true, message: "请输入旧密码", trigger: "blur" },
          {
            min: 8,
            message: "密码至少为8位,包含数字、大小写字母以及特殊字符",
            trigger: "change",
            validator: checkFinanceCode,
          },
          {
            required: true,
            message: "密码至少为8位,包含数字、大小写字母以及特殊字符",
            trigger: "blur",
            validator: checkFinanceCode,
          },
        ],
        password: [
          {
            min: 8,
            message: "密码至少为8位,包含数字、大小写字母以及特殊字符",
            trigger: "change",
            validator: checkFinanceCode,
          },
          {
            required: true,
            message: "密码至少为8位,包含数字、大小写字母以及特殊字符",
            trigger: "blur",
            validator: checkFinanceCode,
          },
          // { required: true, message: "请输入密码", trigger: "blur" }
        ],
        password_confirm: [
          {
            min: 8,
            message: "密码至少为8位,包含数字、大小写字母以及特殊字符",
            trigger: "change",
            validator: checkFinanceCode,
          },
          {
            required: true,
            message: "密码至少为8位,包含数字、大小写字母以及特殊字符",
            trigger: "blur",
            validator: checkFinanceCode,
          },
          // { required: true, message: "请输入确认密码", trigger: "blur" },
        ],
        //   code: [{ required: true, message: "请输入验证码", trigger: "blur" }]
      },
    };
  },
  created() {
    this.get_user();
  },
  methods: {
    UpLoadImg(e) {
      this.imgurl = e.url;
      console.log(this.imgurl, "eeeeeeeeeeeeeeeeeeee");
    },
    async get_user() {
      const res = await this.axios.get("/auth/info");
      console.log(res);
      if (res.code === 1) {
        this.form.name = res.data.user_show_name;
        this.form.mobile = res.data.phone_number;
        this.form.avatar = res.data.avatar_url;
        this.imgurl = res.data.avatar_url;
        this.form.user_name = res.data.user_name;
        // this.form.honor = res.data.honor;
      }
    },
    async onSubmit() {
      let data;
      if (!this.delivery) {
        data = {
          user_show_name: this.form.name,
          user_name: this.form.user_name,
          phone_number: this.form.mobile,
          avatar_url: this.imgurl,
        };
      } else {
        data = this.form;
      }
      const res = await this.axios.put("/auth/updateNoSms", { ...data });
      if (res.code === 1) {
        console.log(res);
        if (this.delivery) {
          this.$router.push({ path: "/login" });
        } else {
          this.$message.success("修改成功");
          location.reload();
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    handleAvatarSuccess(res, file) {
      if (res.code === 1) {
        this.form.avatar = res.data[0].path;
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpg" || "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    async getcode() {
      const res = await this.axios.post("/auth/sms", {
        phone: this.form.mobile,
      });
      console.log(res);
      if (res.code === 1) {
        this.$message.success("发送成功");
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.revise {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  padding: 20px;
  overflow-y: auto;
  .title {
    font-size: 20px;
    font-weight: normal;
    color: #5fa7ff;
    span {
      color: #999999;
      cursor: pointer;
    }
  }
  .main {
    width: 70%;
    padding-top: 40px;
    .el-input {
      width: 60%;
      margin-right: 20px;
    }
    /deep/.avatar-uploader {
      display: flex;
      .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        margin-right: 20px;
      }
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
    .avatar {
      width: 100px;
      height: 100px;
      display: block;
    }
  }
  .btn_code {
    width: 120px;
    height: 40px;
    background: #516cf7;
    border-radius: 8px 8px 8px 8px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    display: inline-block;
    cursor: pointer;
  }
}
</style>
