import { render, staticRenderFns } from "./revise.vue?vue&type=template&id=7e569094&scoped=true&"
import script from "./revise.vue?vue&type=script&lang=js&"
export * from "./revise.vue?vue&type=script&lang=js&"
import style0 from "./revise.vue?vue&type=style&index=0&id=7e569094&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e569094",
  null
  
)

export default component.exports